const Checkbox = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59742 4.36034C2.20124 5.20817 2.24777 6.31641 2.34084 8.53289L2.63997 15.6566C2.73304 17.8731 2.77958 18.9813 3.24686 19.8267C3.6579 20.5703 4.28929 21.1739 5.05055 21.5512C5.91599 21.9801 7.0252 21.9769 9.24363 21.9705L16.3736 21.9498C18.592 21.9434 19.7012 21.9402 20.5305 21.5064C21.2599 21.1248 21.8404 20.5176 22.1889 19.7718C22.5851 18.924 22.5385 17.8157 22.4455 15.5993L22.1463 8.47553C22.0533 6.25905 22.0067 5.15081 21.5395 4.30547C21.1284 3.5619 20.497 2.95821 19.7358 2.58094C18.8703 2.15204 17.7611 2.15525 15.5427 2.16168L8.41271 2.18233C6.19429 2.18875 5.08508 2.19197 4.25586 2.62578C3.52647 3.00737 2.94592 3.61456 2.59742 4.36034ZM18.4239 9.28496C18.7944 8.89746 18.7681 8.271 18.3651 7.88573C17.9622 7.50046 17.3352 7.50228 16.9647 7.88979L10.52 14.6302L7.63827 11.8749C7.23532 11.4896 6.60831 11.4915 6.23781 11.879C5.86731 12.2665 5.89362 12.8929 6.29657 13.2782L9.90794 16.7311C10.3109 17.1164 10.9379 17.1145 11.3084 16.727L18.4239 9.28496Z"
        fill="white"
      />
    </svg>
  );
};

export default Checkbox;
