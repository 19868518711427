import { COLORS } from '../../../models/colors';

const Cross = ({ color, size }) => {
  if (!color) color = COLORS.WHITE;
  switch (size) {
    case 'md':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
            stroke={color}
            strokeWidth="2.33333"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
            stroke={color}
            strokeWidth="2.33333"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

export default Cross;
