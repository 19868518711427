import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import * as Styled from './styled';
import * as UI from '../index';
import { COLORS } from '../../models/colors';
import {
  BUTTON_TYPE,
  TYPOGRAPHY_SIZE,
  TYPOGRAPHY_TYPE,
} from '../../models/types';
import { routes } from '../../models/routes';
import BurgerMenu from './components/BurgerMenu';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > scrollHeight);
  };

  useEffect(() => {
    const startBlock = window.document.querySelector('#start_block');
    setScrollHeight(startBlock.scrollHeight);
  }, []);

  useEffect(() => {
    if (scrollHeight) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    // eslint-disable-next-line
  }, [scrollHeight]);

  const handleClick = () => {
    window.open('https://app.girlsinsync.com/', '_blank');
  };

  return (
    <Styled.Header>
      <Styled.Wrapper isScrolled={isScrolled}>
        <UI.Icon.Logo isScrolled={isScrolled} />
        <Styled.WrapperLinks>
          {routes.map((i, index) => (
            <Styled.Link key={index}>
              <UI.Paragraph
                type={TYPOGRAPHY_TYPE.MEDIUM}
                size={TYPOGRAPHY_SIZE.MEDIUM}
                color={isScrolled ? COLORS.TEXT : COLORS.WHITE}
              >
                {i.label}
              </UI.Paragraph>
            </Styled.Link>
          ))}
        </Styled.WrapperLinks>
        <UI.Button type={BUTTON_TYPE.TRANSPARENT} onClick={handleClick}>
          <Styled.ButtonWarpper>
            <UI.Icon.DoorArrow
              color={isScrolled ? COLORS.TEXT : COLORS.WHITE}
            />
            <UI.Paragraph
              size={TYPOGRAPHY_SIZE.MEDIUM}
              type={TYPOGRAPHY_TYPE.SEMIBOLD}
            >
              LOG IN
            </UI.Paragraph>
          </Styled.ButtonWarpper>
        </UI.Button>
        <BurgerMenu isScrolled={isScrolled} />
        {/* <Styled.Burger>
          <Styled.BurgerLine isScrolled={isScrolled} />
          <Styled.BurgerLine isScrolled={isScrolled} />
          <Styled.BurgerLine isScrolled={isScrolled} />
        </Styled.Burger> */}
      </Styled.Wrapper>
    </Styled.Header>
  );
};

export default Header;
