import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const Header = styled.header`
  z-index: 2;
  padding: 16px 100px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1439px) {
    padding: 16px 30px;
  }

  @media (max-width: 767px) {
    padding: 16px 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 1240px;
  background-color: ${({ isScrolled }) =>
    isScrolled ? COLORS.WHITE : '#00010433'};
  border-radius: 100px;
  padding: 14px 16px;
  button {
    box-shadow: ${({ isScrolled }) =>
      isScrolled ? `0 0 0 1px ${COLORS.TEXT}` : `0 0 0 1px ${COLORS.WHITE}`};
    color: ${({ isScrolled }) => (isScrolled ? COLORS.TEXT : COLORS.WHITE)};
    :hover {
    }
  }
  svg {
    flex-shrink: 0;
  }
  @media (max-width: 1439px) {
    padding: 10px 16px;
    button {
      display: none;
    }
    svg {
      width: 93px;
      height: 28px;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 16px 10px 12px;
  }
`;

export const ButtonWarpper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 1439px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Burger = styled.div`
  display: none;
  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
`;

export const BurgerLine = styled.div`
  border-radius: 100px;
  width: 22px;
  height: 2px;
  background: ${({ isScrolled }) => (isScrolled ? COLORS.BLACK : COLORS.WHITE)};
`;

export const Menu = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: ${({ active }) => (active ? '0' : '-100%')};
  height: 100vh;
  z-index: 10;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: all 0.3s;
  width: ${({ active }) => active && '100%'};
`;

export const Content = styled.div`
  width: 50%;
  position: relative;
  background-color: ${COLORS.DARK};
  height: 100%;
  padding: 85px 30px 0 30px;
  transition: all 0.6s;
  z-index: 10;

  button {
    margin-top: 45px;
    width: 100%;
  }

  @media (max-width: 1439px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 75px 15px 0 15px;
  }
`;

export const Logo = styled.div`
  position: absolute;
  left: 30px;
  top: 15px;
  a {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    left: 15px;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 30px;
  padding: 10px 13px;
  border-radius: 5px;
  background: ${COLORS.TEXT};
  height: 41px;
  cursor: pointer;
  svg {
    path {
      stroke: ${COLORS.WHITE};
    }
  }
  @media (max-width: 767px) {
    right: 15px;
  }
`;

export const Routes = styled.ul`
  list-style-type: none;
  max-width: 462px;
  padding: 0;
  margin-left: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BLACK};
  /* opacity: 0.75; */
  z-index: 5;
`;

export const LogoWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const WrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  button {
    display: block;
    width: 100%;
  }
`;

export const WrapperLinksBurger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
