export const routes = [
  { label: 'About us', link: '/' },
  { label: 'Features', link: '/' },
  { label: 'Steps', link: '/' },
  { label: 'Contact Us', link: '/' },
  { label: 'Referral earnings', link: '/' },
];

export const mediaRoutes = [
  {
    label: 'Privacy Policy',
    link: 'https://docs.girlsinsync.com/Privacy-Policy-10370e44a43180cbac09c349145d8fee',
  },
  {
    label: 'Terms of Use',
    link: 'https://docs.girlsinsync.com/Terms-Conditions-10370e44a43180fbac5bf77cf43a5896',
  },
  {
    label: 'Community Guidelines',
    link: 'https://docs.girlsinsync.com/Community-Guidelines-10370e44a43180b38c65df99b10cbe70',
  },
];
