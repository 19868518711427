const DoubleCheck = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8154 6.06069C14.1182 5.75786 14.1182 5.26688 13.8154 4.96406C13.5125 4.66123 13.0216 4.66123 12.7187 4.96406L5.51271 12.1701L2.18386 8.84123C1.88103 8.5384 1.39005 8.5384 1.08723 8.84123C0.784401 9.14405 0.784401 9.63503 1.08723 9.93786L4.9644 13.815C5.26722 14.1179 5.7582 14.1179 6.06103 13.815L13.8154 6.06069Z"
        fill="#6F59FF"
      />
      <path
        d="M17.6925 6.06069C17.9954 5.75786 17.9954 5.26688 17.6925 4.96406C17.3897 4.66123 16.8987 4.66123 16.5959 4.96406L9.38622 12.1737C9.08184 11.9325 8.63821 11.9525 8.35692 12.2338C8.05409 12.5366 8.05409 13.0276 8.35692 13.3304L8.84157 13.815C9.14439 14.1179 9.63537 14.1179 9.93819 13.815L17.6925 6.06069Z"
        fill="#6F59FF"
      />
      <path
        d="M6.54567 9.32587L6.06103 8.84123C5.7582 8.5384 5.26722 8.5384 4.9644 8.84123C4.66157 9.14405 4.66157 9.63503 4.9644 9.93786L5.44904 10.4225C5.75187 10.7253 6.24285 10.7253 6.54567 10.4225C6.8485 10.1197 6.8485 9.6287 6.54567 9.32587Z"
        fill="#6F59FF"
      />
    </svg>
  );
};

export default DoubleCheck;
