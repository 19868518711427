import React, { useEffect, useState } from 'react';
import {
  NavLink,
  // useNavigate
} from 'react-router-dom';

// import useRoutes from '../../../hooks/useRoutes';
import * as Styled from '../styled';
import * as UI from '../../index';
import { TYPOGRAPHY_SIZE, TYPOGRAPHY_TYPE, BUTTON_TYPE } from '../../../models/types';
import Close from '../../../assets/img/icons/cross-purple.svg';
import { routes } from '../../../models/routes';

import { COLORS } from '../../../models/colors';

const BurgerMenu = ({ isScrolled }) => {
  // const { burger } = useRoutes();

  const [active, setActive] = useState(false);
  // const BURGER = burger;
  const toggleMenu = () => {
    setActive((prevState) => !prevState);
  };
  // const navigate = useNavigate();
  // const onNavigate = (path) => {
  //   setActive((prevState) => !prevState);
  //   navigate(path);
  // };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.style.overflowY = active ? 'hidden' : 'auto';
  }, [active]);

  const handleClick = (link) => {
    window.open(link, '_blank');
  };
  return (
    <>
      <Styled.Burger onClick={toggleMenu}>
        <Styled.BurgerLine isScrolled={isScrolled} />
        <Styled.BurgerLine long isScrolled={isScrolled} />
        <Styled.BurgerLine isScrolled={isScrolled} />
      </Styled.Burger>
      <Styled.Menu active={active ? 'true' : undefined}>
        <Styled.Content>
          <Styled.Logo onClick={toggleMenu}>
            <NavLink to={'/'}>
              <Styled.LogoWrapper>
                <UI.Icon.LogoFooter />
              </Styled.LogoWrapper>
            </NavLink>
          </Styled.Logo>
          <Styled.CloseIcon onClick={toggleMenu}>
            <img src={Close} alt={''} />
          </Styled.CloseIcon>
          <Styled.WrapperBlock>
            <Styled.WrapperLinksBurger>
              {routes.map((i, index) => (
                <Styled.Link key={index}>
                  <UI.Paragraph type={TYPOGRAPHY_TYPE.MEDIUM} size={TYPOGRAPHY_SIZE.MEDIUM} color={COLORS.WHITE}>
                    {i.label}
                  </UI.Paragraph>
                </Styled.Link>
              ))}
            </Styled.WrapperLinksBurger>
            <UI.Button type={BUTTON_TYPE.TRANSPARENT} onClick={() => handleClick('https://tally.so/r/3lR90v')}>
              <UI.Paragraph size={TYPOGRAPHY_SIZE.MEDIUM} type={TYPOGRAPHY_TYPE.SEMIBOLD}>
                Become investor
              </UI.Paragraph>
            </UI.Button>
          </Styled.WrapperBlock>
        </Styled.Content>
        <Styled.Overlay onClick={toggleMenu} />
      </Styled.Menu>
    </>
  );
};

export default BurgerMenu;
