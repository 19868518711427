import React from 'react';
import { useSpring, animated } from 'react-spring';

const AnimationSpring = ({ children, delayX = 1, type, inView }) => {
  const delay = 150;

  const flyInStyleText = useSpring({
    from: { opacity: 0, transform: 'translateY(100%)' },
    to: { opacity: inView ? 1 : 0, transform: inView ? 'translateY(0%)' : 'translateY(100%)' },
    config: { tension: 170, friction: 20 },
    delay: delay * delayX,
  });

  const fadeInStyleText = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },
    config: { tension: 170, friction: 20 },
    delay: delay * delayX,
  });

  return (
    <>
      {type === 'FlyIn' ? (
        <animated.div style={flyInStyleText}>{children}</animated.div>
      ) : (
        <animated.div style={fadeInStyleText}>{children}</animated.div>
      )}
    </>
  );
};

export default AnimationSpring;
