const Verified = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1196_36009)">
        <path
          d="M8.22218 0.896894C8.43512 0.594617 8.88327 0.594617 9.09621 0.896894L10.0626 2.26876C10.2114 2.47991 10.4898 2.55451 10.7242 2.44603L12.247 1.74116C12.5826 1.58585 12.9707 1.80992 13.004 2.17817L13.155 3.84945C13.1782 4.10668 13.382 4.3105 13.6393 4.33374L15.3105 4.48474C15.6788 4.51801 15.9029 4.90613 15.7476 5.24168L15.0427 6.76454C14.9342 6.99893 15.0088 7.27736 15.22 7.4261L16.5918 8.39251C16.8941 8.60545 16.8941 9.0536 16.5918 9.26654L15.22 10.2329C15.0088 10.3817 14.9342 10.6601 15.0427 10.8945L15.7476 12.4174C15.9029 12.7529 15.6788 13.141 15.3105 13.1743L13.6393 13.3253C13.382 13.3485 13.1782 13.5524 13.155 13.8096L13.004 15.4809C12.9707 15.8491 12.5826 16.0732 12.247 15.9179L10.7242 15.213C10.4898 15.1045 10.2114 15.1791 10.0626 15.3903L9.09621 16.7622C8.88327 17.0644 8.43512 17.0644 8.22218 16.7622L7.25577 15.3903C7.10703 15.1791 6.8286 15.1045 6.59422 15.213L5.07135 15.9179C4.7358 16.0732 4.34769 15.8491 4.31442 15.4809L4.16342 13.8096C4.14018 13.5524 3.93635 13.3485 3.67912 13.3253L2.00785 13.1743C1.6396 13.141 1.41552 12.7529 1.57083 12.4174L2.2757 10.8945C2.38419 10.6601 2.30958 10.3817 2.09844 10.2329L0.726568 9.26654C0.424291 9.0536 0.424291 8.60545 0.726568 8.39251L2.09844 7.4261C2.30958 7.27736 2.38419 6.99893 2.2757 6.76454L1.57083 5.24168C1.41552 4.90613 1.6396 4.51801 2.00785 4.48474L3.67912 4.33374C3.93635 4.3105 4.14018 4.10668 4.16342 3.84945L4.31442 2.17817C4.34769 1.80992 4.7358 1.58585 5.07135 1.74116L6.59422 2.44603C6.8286 2.55451 7.10703 2.47991 7.25577 2.26876L8.22218 0.896894Z"
          fill="#6F59FF"
        />
        <path
          d="M5.4519 8.8295L7.59015 10.9678L9.7284 8.8295L11.8667 6.69125"
          stroke="white"
          strokeWidth="1.28295"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1196_36009">
          <rect x="0.106201" y="0.276505" width="17.106" height="17.106" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Verified;
